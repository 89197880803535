.story-section h2{
    padding: 2rem 5rem 1rem 5rem ;
    font-size: 32px;
    color: black;
}
.story-section .date{
    padding: 0 5rem ;
    font-size: 14px;
    color: #6C727F;
}
.story-section .profile{
    padding: 0 5rem ;
    font-size: 14px;
    color:black;
    font-weight: 500;
}
.story-section .description{
    padding: 0 5rem ;
    font-size: 16px;
    color:black;
    font-weight: 500;
}
.story-section .view-btn{
    background: white;
    border-color: #3781C5;
    margin: 0 5rem ;
    color: #3781C5;
    padding: 0.5rem 2.5rem;
    border-radius: 30px;
    text-transform: uppercase;
}
.story-section .view-btn:hover{
    background: #3781C5;
    border-color: #3781C5;
    margin: 0 5rem ;
    color: white;
}
.load-btn{
    background: white !important;
    border-color: #3781C5 !important;
    color: #3781C5 !important;
    padding: 0.5rem 2.5rem !important;
    border-radius: 30px !important;
    margin: 2rem auto !important;
    display: flex !important;
    text-transform: uppercase;

}
.load-btn:hover{
    background: #3781C5 !important;
    border-color: #3781C5 !important;
color: white !important;
}
.story-detailed-view{
    margin-top: 6rem;
}
.story-detailed-view h1{
    font-size: 32px;
    color: black;
    margin: 2rem 0;
}
.profile-section{


    text-align: center;
}
.profile-section-first-part{
    background: #F8F8F8;
    padding: 3rem;
}
.profile-section .story-btn{
    background: #3781C5;
    border-color: #3781C5;
    margin: .5rem auto ;
    color: white;
    padding: 0.5rem;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 12px;
    width: 15rem;
}
iframe{
    width: 100%;
    margin: auto;
    height: 15rem;
    margin-top: 2rem;
}