/* src/components/Filters.css */
.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2rem 0;
}

.input-group {
    display: flex;
    align-items: center;
    margin:20px 10px;
    flex: 1 0 calc(25% - 20px); /* Adjust width for 4 items in a row with spacing */
    padding: .2rem 0.5rem;
    background: #F8F8F8;
    border-radius: 50px;
}

.input-group .icon {
    width: 18px; /* Adjust icon size as needed */
    height: 18px;
    margin:13px 20px; /* Adjust margin for spacing */
}

.input-group select,
.input-group input {
    padding: 10px;
    flex: 1;
    border-radius: 5px;
    appearance: none; /* Remove default styling */
    -webkit-appearance: none; /* For older versions of Safari */
    background:transparent;
    border: 0;
}

@media (max-width: 600px) {
    .input-group {
        flex-direction: column;
        flex: 1 0 100%; /* Full width for each item on mobile */
    }
}
