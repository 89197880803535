/*contact */
/* src/styles/ContactUs.css */
.contact-us {
    padding: 20px;
}
.contact-us-title{
    text-align: center;
    margin: 2rem 0;
}
.contact-us-title h2{
    color: #2A4EA6;
    font-weight: 600;
    font-size: 32px;
}
.contact-us-title p{
    color:#6C727F;
    font-size: 16px;
    font-weight: 500;
}
.contact-form {
    display: flex;
    flex-direction: column;
    background: #F8F8F8;
    padding: 2rem;
    border-radius: 20px;
}
.form-title{
    margin-bottom: 2rem;
}
.contact-form-row {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    font-size: 14px;
}

.form-group input
{
    width: 100%;
    border: 0;
    border-radius: 50px;
}
.form-group input::placeholder
{
    color: #B4B4B4;
    font-size: 12px;
}
.form-group textarea {
    resize: none;
    width: 100%;
    padding: 10px 5rem 4px 1rem;
    border: 0;
    border-radius: 20px;
    height: 7rem;
}
.form-group textarea::placeholder  {
    color: #B4B4B4;
    font-size: 12px;
}
.full-name-input{
    padding: 4px 3rem 4px 1rem;
}
input:focus,
textarea:focus {
    outline: none;
    border: none; /* Optional: remove border as well */
}
.contact-card {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background:#F8F8F8 ;
    padding: 2.4rem 1rem;
    border-radius: 20px;
}

.contact-card img {
    margin-right: 15px;
    width:3rem;
}
.contact-card .card-text-title {
    margin-right: 15px;
    display: block;
    font-size: 18px;
    font-weight: 600;
}
.contact-card .card-text-value {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color:#3781C5;
}
button[type="submit"] {
    padding: 10px 3rem;
    border: none;
    background-color: #3781C5;
    color: white;
    border-radius: 50px;
    cursor: pointer;
    text-transform: uppercase;
    width: auto;
    align-self: flex-end;
    margin-top: 10px;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}
.PhoneInput{
    background: white;
    border-radius: 50px;
    padding: 4px 5rem 4px 1rem;
}
.form-group .react-tel-input .flag-dropdown .selected-flag {
    border-radius: 50%; /* Make the flag container circular */
    overflow: hidden;
    width: 32px; /* Adjust the size as needed */
    height: 32px; /* Adjust the size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-group .react-tel-input .flag-dropdown .selected-flag .flag {
    border-radius: 50%; /* Make the flag image circular */
    width: 24px; /* Adjust the size as needed */
    height: 24px; /* Adjust the size as needed */
}

/*end contact*/