/*about*/
.about-title{
    text-align: center;
    margin: 2rem 0;
}
.about-title h2{
    color: #2A4EA6;
    font-weight: 600;
    font-size: 32px;
}
.about-title p{
    color:#6C727F;
    font-size: 16px;
    font-weight: 500;
}
.about-body{
    margin-top: 3rem;
}
.about-body p{
    font-size: 18px;
    color: #6C727F;
    margin-top: 1.5rem;
    font-weight: 500;
    line-height: 1.8;
}
.about-body img{
    height: 25rem;
    margin-left: 4rem;
}
.about-number-info{
    background:url("../../Assets/Images/about-number-section-bg.png");
    background-position:center ;
    text-align: center;
    margin:5rem 0;
    color: white;
    padding: 2rem;
}
.about-number-info img{
    text-align: center;
    height:3rem;
    margin: auto;
}
.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-right: 1px solid #C8C8C8;
    padding: 10px;
}

.info-container:last-child {
    border-right: none;
}

.info-container .info-number {
    font-size: 24px;
    margin: 5px 0;
    color: white;
}

.info-container .info-title {
    font-size: 16px;
    color: white;
    margin: 0;
}
.about-number-info-title{
    margin: 2rem auto;
}
.about-number-info-title h2{
    color: #ffffff;
    font-weight: 600;
    font-size: 32px;
}
.about-number-info-title p{
    font-size: 18px;
    color: #ffffff;
    margin-top: .5rem;
    font-weight: 500;
    line-height: 1.8;
}
/*end about*/