




/*partner*/
.partner-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
}
/* swiper-container is the main container for Swiper */
.swiper-container {
    width: 100%; /* Adjust width as needed */
    margin-left: auto;
    margin-right: auto;
}

/* swiper-wrapper contains slides */
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

/* swiper-slide is each individual slide */
.swiper-slide {
    flex-shrink: 0;
    width: auto; /* Adjust width of slides */
    height: auto; /* Adjust height of slides */
}

/* swiper-pagination is the container for pagination dots */
.swiper-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px; /* Adjust distance from bottom */
    display: flex;
    justify-content: center;
}

/* swiper-pagination-bullet is each pagination dot */
.swiper-pagination-bullet {
    width: 10px; /* Adjust width of dots */
    height: 10px; /* Adjust height of dots */
    background-color: #000; /* Adjust color of dots */
    opacity: 0.5; /* Adjust opacity of dots */
    border-radius: 50%; /* Make dots circular */
    margin: 0 5px; /* Adjust margin between dots */
    cursor: pointer;
    transition: opacity 0.3s ease;
}

/* Active pagination dot */
.swiper-pagination-bullet-active {
    opacity: 1;
}

/* Additional styles for responsiveness and other configurations */
@media (max-width: 768px) {
    .swiper-pagination {
        bottom: 5px; /* Adjust distance from bottom for smaller screens */
    }
}


/*end partner*/

