.swiper-pagination .swiper-pagination-bullet-active {
    background-color:transparent !important;
    border: 2px black solid
}
.swiper-pagination .swiper-pagination-bullet {
    background-color: #C8C8C8
}
.swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    opacity: 1;
    border-radius: 50%;
    cursor: pointer
}
