.wefe-index .wefe-index-header {
    text-align: center;
}
.wefe-index .wefe-index-header h1{
    margin-top: 7rem;
    color: #3781C5;
    font-size: 42px;
    font-weight: 500;
}
.wefe-index .wefe-index-header p{
    color: #6C727F;
    font-size: 18px;
    font-weight: 400;
}
.wefe-index .filters {
    text-align: center;
    margin: auto;
    width: 100%;
    display: block;
}
.wefe-index .inputs-filter{
    margin: auto;

}
.wefe-index .filter-select {
    position: relative;
    margin: auto;
    width: 50%;
}
.wefe-index .input-group{
    width: 15rem !important;
}
.wefe-index .filter-select .filter-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.wefe-index .nav-tabs{
    border-bottom: 0 !important;
}
.wefe-index .nav-tabs .nav-link {
    border: 1px solid #F8F8F8 !important;
    margin: 0 1rem;
    padding: .5rem 1rem;
    background: #F8F8F8;
    border-radius: 20px !important;
    color: #BABABA !important;
    width: 10rem;
}

.wefe-index .nav-tabs .nav-link.active {
    background-color: #3781C5 !important;
    color: white !important;
}
.wefe-index .input-group-text{
    background: transparent !important;
    border: 0 !important;
}
.wefe-index .input-group-text img
{
    width: 1.3rem;
    margin-top: .3rem;
}
.wefe-index .card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding:5px 20px;
    margin: 10px 0;
}
.wefe-index .wefe-index-card{
background: #F8F8F8 !important;
}
.wefe-index .card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    background: transparent !important;

}
.country-info img{
    width: 2.5rem;
    height: 1.5rem;
}
.rank-title{
    font-size: 9px;
}
.rank-value{
    color:#3781C5;
    font-size: 21px;
    font-weight: 500;
}
.wefe-index .card-header h5 {
    margin: 0;
}

.wefe-index .indicator {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.wefe-index .indicator img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.wefe-index .indicator .indicator-info {
    flex-grow: 1;
}

.wefe-index .indicator .indicator-info .indicator-name {
    display: flex;
    justify-content: space-between;
}

.wefe-index .indicator .indicator-info .indicator-name span {
    font-size: 0.9em;
}

.wefe-index .progress {
    height: 10px;
    background-color: #e9ecef;
    border-radius: 5px;
}

.wefe-index .progress-bar {
    height: 10px;
    border-radius: 5px;
}

.wefe-index .water-progress {
    background-color: #007bff;
}

.wefe-index .energy-progress {
    background-color: #ffc107;
}

.wefe-index .food-progress {
    background-color: #28a745;
}

.wefe-index .ecosystem-progress {
    background-color: #dc3545;
}
.wefe-index .form-section {
    margin-bottom: 20px; /* Example margin for separation */
}

.wefe-index .nav-section {
    margin-top: 20px; /* Example margin for separation */
}


.wefe-index-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.wefe-index-card .country-info {
    display: flex;
    align-items: center;
}

.wefe-index-card .country-info img {
    margin-right: 0.5rem;
}

.wefe-index-card .indicator {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.wefe-index-card .indicator img {
    margin-right: 0.5rem;
}

.wefe-index-card .indicator-info {
    flex-grow: 1;
}

.wefe-index-card .progress {
    position: relative;
    height: 20px;
    width: 100%;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    overflow: hidden;
    margin-bottom: 0.5rem;
    display: flex;
}

.wefe-index-card .progress-bar {
    height: 100%;
}

.progress-bar-first {
    background-color: #007bff; /* Example color 1 */
}

.progress-bar-second {
    background-color: #6c757d; /* Example color 2 */
}

.wefe-index-card .indicator-name {
    display: flex;
    justify-content: space-between;
}
