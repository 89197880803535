/* src/components/Banner.css */
.banner {
    background:url("../Assets/Images/about-number-section-bg.png");
    background-size: cover;
    padding: 20px ;
    text-align: center;
    color: white;
    position: relative;
    width: 100%;
}

.banner h1 {
    margin: 8rem auto .5rem auto;
}

.add-button {

    background: transparent;
    color: white;
    border: none;
    cursor: pointer;
    margin: 2rem auto;
}
