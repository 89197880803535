.swiper-pagination {
    position: relative !important;
    margin-top: 2rem !important;

}
.swiper-pagination-clickable .swiper-pagination-bullet{
    background-color: black;
}

/*slider*/
.slider-container {
    position: relative;
    overflow: hidden;

}

.slide {
    /*position: relative;*/
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Adjust z-index of content inside .slide if needed */
.slide .content {
    position: relative;
    z-index: 2; /* Ensure content appears above the overlay */
}
.slide video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    text-align: center;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.content h1{
    font-size:62px;
    font-weight: 800;
}
.content p{
    font-size:42px;
}
.controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 10;
}

.dots {
    position: absolute;
    bottom: 70px;
    width: 100%;
    text-align: center;
}

.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color:#C8C8C8;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

}

.dot.active {
    background-color: #61B4E2;
    border: 3px #3781C5 solid;
}

.wave {
    position: absolute;
    bottom: -25rem;
    width: 100%;
    height: 49rem;
    background: url("../../Assets/Images/banner-bottom.png");
    background-position: center;
    transform: translateY(40%);
    border: none; /* Add this line to remove any borders */
}

/*end slider*/