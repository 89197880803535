/* src/components/PaginationDots.css */
.pagination-dots {
    text-align: center;
}

.pagination-dots span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background: #ddd;
}

.pagination-dots span.active {
    background: #007bff;
}
