
/*footer*/
.footer {
    overflow: hidden;
    background:#F1F5F9;
    color: #667388;
}
.footer .footer-content {
    padding: 60px 0;
}
.first-part img{
    width:6rem;
    margin-right: 1.25rem;
}
.first-part  p{
    margin-top: 2rem;
    font-size: 14px;
    color: #667388;
}
.footer .footer-heading {
    color: #364153;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 15px;
}
.footer .footer-links li
{
    margin-bottom: 5px;
}
.footer .footer-links li a {
    color: #677489;
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
}

.footer .footer-links li a:hover,
.footer .footer-links li a:focus {
    color: black;
}

.footer .footer-legal {
    background: #354052;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .footer-legal .social-links a {
    text-align: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #677489;
    line-height: 40px;
}

.footer .footer-legal .social-links a:hover {
    background-color: white;
}
.footer .copyright {
    font-size: 13px;
    margin-top: .7rem;
}
.footer .copyright strong {
    font-weight: 400;
}
/*end footer*/

