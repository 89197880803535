/* Menu.css */
.login-menu {
    position: absolute;
    top: 40px;
    right: 10px;
    background: white;
    border: 1px solid #ccc;
    padding: 1rem 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius:20px ;
    border-top-right-radius: 0;
}

.login-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.login-menu li {
    margin-bottom: 10px;
    text-align: center;
}

.login-menu li a {
    text-decoration: none;
    color: #757575;
}
.login-menu li:first-child a {
    border-bottom: 1px solid #DBDBDB;
    display: inline-block;
    width: 80px;
    padding:0 0 10px 0;
}



.login-menu li a:hover {
    color: #3781C5;
}
