/* Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 10rem auto;
}

.login-container .login-box {
    background: white;
    border-radius: 8px;
    text-align: center;

}

.login-container .login-box h1 {
    margin-bottom: 10px;
    font-size: 42px;
    color: #3E5FAD;
}

.login-container .login-box h2 {
    margin-bottom: 30px;
    font-size: 21px;
    color: #6C727F;
}

.login-container .form-group {
    margin-top: 2rem;
    margin-bottom: 15px;
    text-align: left;
}
.login-container .form-group input{
    margin-bottom: 15px;
    text-align: left;
    background: #F8F8F8;
    padding: .5rem 1rem;

}

.login-container .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
}

.login-container .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.login-container .login-container form{
    margin: 3rem auto;
    width: 80%;
}
.login-container .btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.login-container .btn:hover {
    background-color: #0056b3;
}

.login-container .forgot-password-link {
    display: block;
    margin-top: 15px;
    color: #007bff;
    text-decoration: none;
}

.login-container .forgot-password-link:hover {
    text-decoration: underline;
}
