/* src/pages/NewsAndEvents.css */

.news-and-events {
    /*max-width: 1200px;*/
    margin: 0 auto;
    /*padding: 20px;*/
}
.news-and-events-page-content{
    padding:0 10rem 3rem 10rem;
}
.cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;


}

.card {
    border-radius: 20px !important;
    overflow: hidden;
    border: 0 !important;
    background: transparent !important;
}

.card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    /*border-radius: 20px;*/
}

.card-footer {
    padding: 10px;
    background: transparent !important;
}

.card-footer span {
    display: block;
    font-size:14px;
    color: #394150;
    margin: 10px 0;
    padding:0 15px;
}

.card-footer p {
    margin: 10px 0;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding:0 15px;
}
.footer-button {
    display: flex;
    justify-content: space-between; /* Aligns items with space between them */
    align-items: center; /* Centers items vertically */
    border-top: 1px #D6D6D6 solid;
    margin-top: 20px;
}

.read-more {
    order: -1; /* Moves the button to the left */
    /* Additional styling for the button */
}

.footer-content {
    flex: 1; /* Takes up remaining space */
    /* Additional styling for the content */
}

.arrow-icon {
    /* Additional styling for the arrow */
}

.card-footer button {
    background-color: transparent;
    color: #000000;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}
.pagination-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-dots span {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color:#C8C8C8;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

}

.pagination-dots span.active {
    background-color: #61B4E2;
    border: 3px #3781C5 solid;
}








