/* src/pages/EventDetails.css */

.event-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.slider {
    margin-bottom: 20px;
}

.event-info {
    display: flex;
}

.left-part {
    flex: 1;
}

.right-part {
    flex: 1;
    margin-left: 20px;
}

.text h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.text p {
    margin-bottom: 20px;
}

.text hr {
    margin: 20px 0;
}

.info {
    margin-bottom: 20px;
}

.info div {
    margin-bottom: 10px;
}

.agenda {
    margin-bottom: 20px;
}

.agenda h3 {
    margin-bottom: 10px;
}

.agenda ul {
    padding-left: 20px;
}

.event-description,
.event-impact,
.key-learnings {
    margin-bottom: 20px;
}

.event-description h3,
.event-impact h3,
.key-learnings h3 {
    margin-bottom: 10px;
}
