/* src/Components/SliderWithThumbnails.css */
.slider-container {
    max-width: 100%; /* Adjust max-width as needed */
    margin: 0 auto; /* Center the slider */
}

.slick-slider {
    margin-bottom: 20px; /* Add space between the sliders */
}

.slick-slide img {
    width: 100%;
    height: auto;
}

.thumbnail-slider .slick-slide {
    padding: 0 5px; /* Adjust padding around thumbnails */
}

.thumbnail-slider .slick-prev, .thumbnail-slider .slick-next {
    top: 50%; /* Position arrows vertically */
    transform: translateY(-50%);
}
/* src/Components/SliderWithThumbnails.css */
/* src/Components/SliderWithThumbnails.css */

/* Styling for thumbnail slider arrows */
.thumbnail-slider .slick-prev,
    /* src/Components/SliderWithThumbnails.css */

    /* Styling for thumbnail slider arrows */
.thumbnail-slider .slick-prev,
.thumbnail-slider .slick-next {
    background-color: red; /* Red background */
    color: black; /* Black arrow color */
    width: 40px; /* Set width of arrows */
    height: 40px; /* Set height of arrows */
    border-radius: 50%; /* Make arrows circular */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    cursor: pointer;
}

.thumbnail-slider .slick-prev {
    left: -30px; /* Adjust left position */
}

.thumbnail-slider .slick-next {
    right: -30px; /* Adjust right position */
}
