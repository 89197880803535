/*!* src/components/Card.css *!*/
/*.card {*/
/*    padding: 10px;*/
/*    margin-bottom: 20px;*/
/*    border: 0;*/
/*}*/

/*.card img {*/
/*    width: 100%;*/
/*    height: auto;*/
/*}*/

/*.card-footer {*/
/*    padding: 10px;*/
/*}*/

/*.card-footer span {*/
/*    display: block;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.card-footer .read-more {*/
/*    background: none;*/
/*    border: none;*/
/*    color: blue;*/
/*    cursor: pointer;*/
/*}*/
