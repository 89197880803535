:root {
    scroll-behavior: smooth;
}
/*header*/
.header {
    transition: all 0.5s;
    z-index: 997;
    background: white;
    display: flex;
    padding: .3rem 0;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
}

.logo {
    display: flex;
    align-items: center;
}

.vertical-line {
    width: 1px; /* Adjust the width as needed */
    height:4rem; /* Adjust the height as needed */
    background-color: black; /* Adjust the color as needed */
    margin: 0 15px; /* Adjust the margin as needed */
}

.header .logo img {
    height: 4rem;
}


.search-form-wrap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    transition: 0.3s all ease;
    visibility: hidden;
    opacity: 0;
}

.search-form-wrap .search-form {
    position: relative;
}

.search-form-wrap .search-form .form-control {
    width: 300px;
    border: none;
    box-shadow: 0 15px 20px -10px rgba(0,0,0, 0.1);
    padding-left: 40px;
    padding-right: 40px;
}

.search-form-wrap .search-form .form-control:active,
.search-form-wrap .search-form .form-control:focus {
    outline: none;
    box-shadow: none;
}

.search-form-wrap .search-form .icon {
    position: absolute;
    left: 0;
    top: 7px;
    opacity: 0.5;
    left: 10px;
}

.search-form-wrap .search-form .btn {
    position: absolute;
    top: 2px;
    right: 4px;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 30px;
}

.search-form-wrap .search-form .btn:active,
.search-form-wrap .search-form .btn:focus {
    outline: none;
    box-shadow: none;
}

.search-form-wrap.active {
    visibility: visible;
    opacity: 1;
}

section {
    scroll-margin-top: 70px;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
    .navbar {
        padding: 0;
    }

    .navbar ul {
        margin: 0 3rem 0 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navbar li {
        position: relative;
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 1rem 10px 2rem;
        font-family: sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #3781C5;
        white-space: nowrap;
        transition: 0.3s;
        text-decoration: none;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
        color: black;
    }

    .navbar .getstarted,
    .navbar .getstarted:focus {
        background: blue;
        padding: 8px 20px;
        margin-left: 30px;
        border-radius: 4px;
        color: white;
    }

    .navbar .getstarted:hover,
    .navbar .getstarted:focus:hover {
        color: white;
        background: rgba(0,0,0, 0.8);
    }
    .right-menu img{
        cursor: pointer;

    }
    .right-menu .login-icon{
        width: 2.1rem;

    }
    .right-menu .search-icon{
        width: 1.3rem;
        margin-right: .8rem;
    }
}



.mobile-nav-toggle {
    display: none;
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
    .navbar {
        padding: 0;
        z-index: 9997;
    }

    .navbar ul {
        display: none;
        position: absolute;
        inset: 55px 15px 15px 15px;
        padding: 10px 0;
        margin: 0;
        border-radius: 10px;
        background-color: white;
        overflow-y: auto;
        transition: 0.3s;
        z-index: 9998;
    }

    .navbar a,
    .navbar a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        font-family:sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: blue;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navbar a i,
    .navbar a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
    }

    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
        color: blue;
    }

    .navbar .dropdown ul,
    .navbar .dropdown .dropdown ul {
        position: static;
        display: none;
        z-index: 99;
        padding: 10px 0;
        margin: 10px 20px;
        background: white;
        transition: all 0.5s ease-in-out;
        box-shadow: 0px 0px 30px rgba(0,0,0, 0.1);
    }

    .navbar .dropdown>.dropdown-active,
    .navbar .dropdown .dropdown>.dropdown-active {
        display: block;
    }

    .mobile-nav-toggle {
        display: inline-block;
        color: gray;
        font-size: 28px;
        cursor: pointer;
        line-height: 0;
        transition: 0.5s;
    }

    .mobile-nav-toggle.bi-x {
        color: black;
    }

    .mobile-nav-active {
        overflow: hidden;
    }

    .mobile-nav-active .mobile-nav-toggle {
        position: fixed;
        top: 15px;
        right: 15px;
        z-index: 9999;
        color: white;
    }

    .mobile-nav-active .navbar {
        position: fixed;
        overflow: hidden;
        inset: 0;
        background: rgba(0,0,0, 0.6);
        transition: 0.3s;
    }

    .mobile-nav-active .navbar>ul {
        display: block;
    }
}
/* Example CSS */
.navbar-mobile {
    display: block;
}

.search-form-wrap {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

.search-form-wrap .form-control {
    margin-right: 10px;
}

.js-search-open {
    cursor: pointer;
}

.mobile-nav-toggle {
    display: none;
}

@media (max-width: 991.98px) {
    .mobile-nav-toggle {
        display: inline-block;
    }

    .navbar {
        display: none;
    }

    .navbar-mobile {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        z-index: 999; /* Ensure it appears above other content */
    }
}
